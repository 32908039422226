// src/hooks/useAuth.js
import { useAuth0 } from '@auth0/auth0-react';

export const useAuth = () => {
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

  console.log("Authentication status:", isAuthenticated); // Add this line for debugging

  return {
    login: loginWithRedirect,
    logout: () => logout({ returnTo: window.location.origin }),
    user,
    isAuthenticated,
  };
};
