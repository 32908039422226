// src/components/Dashboard.js
import React from 'react';

const Campaigns = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Campaigns</h1>
      <p>This is the Campaigns page, accessible only to authenticated users.</p>
    </div>
  );
};

export default Campaigns;
