import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';

const icons = {
  facebook: <FaFacebook size={20} />,
  instagram: <FaInstagram size={20} />
};

const PlatformButton = ({ platform, isConnected, onClick }) => {
  return (
    <button 
      onClick={onClick} 
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        marginBottom: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        backgroundColor: '#fff',
        cursor: 'pointer',
        width: '100%',
        textAlign: 'left'
      }}
    >
      {icons[platform]}
      <span style={{ marginLeft: '10px', flexGrow: 1 }}>{platform.charAt(0).toUpperCase() + platform.slice(1)}</span>
      {isConnected ? (
        <AiFillCheckCircle size={20} color="green" />
      ) : (
        <AiFillCloseCircle size={20} color="red" />
      )}
    </button>
  );
};

export default PlatformButton;
