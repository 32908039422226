// src/components/Dashboard.js
import React from 'react';

const Dashboard = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Dashboard</h1>
      <p>This is the dashboard page, accessible only to authenticated users.</p>
    </div>
  );
};

export default Dashboard;
