// src/components/LoginButton.js
import React from 'react';
import { useAuth } from '../hooks/useAuth';

const LoginButton = () => {
  const { login, isAuthenticated } = useAuth();

  if (isAuthenticated) return null;

  return (
    <button onClick={login} style={{ marginLeft: '10px' }}>
      Login
    </button>
  );
};

export default LoginButton;
