// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import GlobalStyle from './globalStyles';
import Login from './components/Login';
import Dashboard from './pages/Dashboard';
import Integrations from './pages/Integrations';
import Analytics from './pages/Analytics';
import Campaigns from './pages/Campaigns';
import Content from './pages/Content';
import ManageClients from './pages/ManageClients';
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';


function App() {
  return (
    <Auth0ProviderWithHistory>
      <Router>
        <GlobalStyle />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/campaigns" element={<Campaigns />} />
          <Route path="/content" element={<Content />} />
          <Route path="/manage-clients" element={<ManageClients />} />
        </Routes>
      </Router>
    </Auth0ProviderWithHistory>
  );
}

export default App;
