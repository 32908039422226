// src/components/Home.js
import React from 'react';

const Home = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Welcome to the Home Page</h1>
      <p>This is the main landing page of our application.</p>
    </div>
  );
};

export default Home;
