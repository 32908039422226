// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import logo from '../assets/logo.png';
import { useAuth } from '../hooks/useAuth'; // Import useAuth hook

const NavbarContainer = styled.nav`
  padding: 15px 30px;
  background-color: #a0a4a8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const NavLinks = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const Logo = styled.img`
  height: 30px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  transition: color 0.3s;

  &:hover {
    color: #ffd1ba;
  }
`;

const Navbar = () => {
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const { isAuthenticated } = useAuth(); // Get isAuthenticated state

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('/api/clients'); // Replace with your API endpoint
        setClients(response.data);
        setSelectedClientId(response.data[0]?.id);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const handleClientChange = (event) => {
    setSelectedClientId(Number(event.target.value));
  };

  return (
    <NavbarContainer>
      <NavLinks>
        <Link to="/">
          <Logo src={logo} alt="Logo" />
        </Link>
        {isAuthenticated && (
          <>
            <StyledLink to="/integrations">Connections</StyledLink>
            <StyledLink to="/analytics">Analytics</StyledLink>
            <StyledLink to="/campaigns">Campaigns</StyledLink>
            <StyledLink to="/content">Content</StyledLink>
          </>
        )}
      </NavLinks>
      <NavLinks>
        {isAuthenticated && (
          <>
            <select value={selectedClientId} onChange={handleClientChange}>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
            <StyledLink to="/manage-clients">My Clients</StyledLink>
            <StyledLink to="/dashboard">Dashboard</StyledLink>
            <LogoutButton />
          </>
        )}
        {!isAuthenticated && <LoginButton />}
      </NavLinks>
    </NavbarContainer>
  );
};

export default Navbar;
