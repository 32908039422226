// src/components/Dashboard.js
import React from 'react';

const Analytics = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Analytics</h1>
      <p>This is the Analytics page, accessible only to authenticated users.</p>
    </div>
  );
};

export default Analytics;
