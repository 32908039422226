// src/components/FacebookLoginButton.js
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import axios from 'axios';

const FacebookLoginButton = ({ onConnect }) => {
  const responseFacebook = async (response) => {
    console.log(response); // This will give you the access token and other details

    if (response.accessToken) {
      try {
        // Send the access token to your backend to store and use for further API calls
        await axios.post('/api/facebook/auth', { accessToken: response.accessToken });

        // Callback function to indicate successful connection
        onConnect();
      } catch (error) {
        console.error('Error during Facebook authorization:', error);
      }
    }
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID} // Add your Facebook App ID here
      autoLoad={false}
      callback={responseFacebook}
      render={(renderProps) => (
        <button onClick={renderProps.onClick} style={{ padding: '10px', background: '#4267B2', color: 'white', border: 'none', borderRadius: '5px' }}>
          Connect Facebook
        </button>
      )}
    />
  );
};

export default FacebookLoginButton;
