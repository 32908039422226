import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PlatformButton from '../components/PlatformButton';
import FacebookLoginButton from '../components/FacebookLoginButton';

const Integrations = () => {
  const [loading, setLoading] = useState(true);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('/api/clients'); // Replace with your API endpoint
        setClients(response.data);
        setSelectedClientId(response.data[0]?.id);
      } catch (error) {
        console.error('Error fetching clients:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const handleConnect = (platform) => {
    window.location.href = `/connect/${platform}`;
  };

  const selectedClient = clients.find(client => client.id === selectedClientId);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ display: 'flex' }}>
      {/* Left Side Menu */}
      <div style={{ width: '200px', padding: '20px', borderRight: '1px solid #ddd' }}>
        <h3>Connect Platform</h3>
        
        <PlatformButton
          platform="facebook"
          isConnected={selectedClient?.facebookConnected}
          onClick={() => handleConnect('facebook')}
        />
        <PlatformButton
          platform="instagram"
          isConnected={selectedClient?.instagramConnected}
          onClick={() => handleConnect('instagram')}
        />

        {/* Add the Facebook Login Button */}
        <FacebookLoginButton onConnect={() => console.log('Facebook connected!')} />
      </div>

      {/* Main Content */}
      <div style={{ padding: '20px', flexGrow: 1 }}>
        {selectedClient ? (
          <div>
            <h3>{selectedClient.name}</h3>
            {/* You can add more details about the selected client here */}
          </div>
        ) : (
          <div>No client selected</div>
        )}
      </div>
    </div>
  );
};

export default Integrations;
