// src/components/LogoutButton.js
import React from 'react';
import { useAuth } from '../hooks/useAuth';

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth();

  if (!isAuthenticated) return null;

  return (
    <button onClick={logout} style={{ marginLeft: '10px' }}>
      Logout
    </button>
  );
};

export default LogoutButton;
