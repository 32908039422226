// src/components/ManageClients.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManageClients = () => {
  const [clients, setClients] = useState([]);
  const [newClientName, setNewClientName] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('/api/clients'); // Replace with your API endpoint
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const handleAddClient = async () => {
    try {
      const response = await axios.post('/api/clients', { name: newClientName }); // Replace with your API endpoint
      setClients([...clients, response.data]);
      setNewClientName('');
    } catch (error) {
      console.error('Error adding client:', error);
    }
  };

  const handleRemoveClient = async (clientId) => {
    try {
      await axios.delete(`/api/clients/${clientId}`); // Replace with your API endpoint
      setClients(clients.filter(client => client.id !== clientId));
    } catch (error) {
      console.error('Error removing client:', error);
    }
  };

  return (
    <div>
      <h2>Manage Clients</h2>
      <div>
        <input
          type="text"
          placeholder="New client name"
          value={newClientName}
          onChange={(e) => setNewClientName(e.target.value)}
        />
        <button onClick={handleAddClient}>Add Client</button>
      </div>
      <ul>
        {clients.map(client => (
          <li key={client.id}>
            {client.name} <button onClick={() => handleRemoveClient(client.id)}>Remove</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageClients;
