// src/components/Login.js
import React from 'react';
import { useAuth } from '../hooks/useAuth'; // Custom hook for authentication

const Login = () => {
  const { login } = useAuth();

  return (
    <div style={{ padding: '20px' }}>
      <h1>Login</h1>
      <button onClick={login}>Login with Auth0</button>
    </div>
  );
};

export default Login;
